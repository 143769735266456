@charset "utf-8";
@import './variables.scss';
@import '../node_modules/bulma/sass/index';

.hero-body {
  background: $primary;
  background: -webkit-linear-gradient(left, $primary, $dark);
  background: -moz-linear-gradient(left, $primary, $dark);
  background: linear-gradient(to right, $primary, $dark);
}

.hero-head {
  background-color: transparent;
}

.hero-foot {
  background-color: transparent;
  visibility: hidden;
}

.link-container {
  background: transparent;
  visibility: hidden;
}